<template>
  <div class="comm-page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div
          @click="changeHeadIndex(index)"
          class="li"
          :class="{ active: headIndex == index }"
          v-for="(item, index) in 2"
          :key="index"
        >
          {{ index == 0 ? "晋升" : "获奖" }}
        </div>
      </div>
      <div class="award" @click="$refs.pop.open()" v-if="headIndex == 1 && userInfo.position != 4">奖励</div>
    </div>
    <!--rate_page_head end-->

    <template v-if="headIndex == 0">
      <!--honor-head start-->
      <div class="honor-head">
        <div class="head-nav">
          <div
            class="li"
            @click="changePromoteIndex(index)"
            :class="{ active: promoteIndex == index }"
            v-for="(item, index) in 2"
            :key="index"
          >
            {{ index == 0 ? "我的晋升" : "他人晋升" }}
          </div>
        </div>
        <el-button
          class="ind-add-btn"
          v-if="promoteIndex == 0"
          @click="applyPromote"
          >申请晋升</el-button
        >
      </div>
      <!--honor-head end-->
      <!--ind-main start-->
      <div class="ind-main" v-if="promoteIndex == 0">
        <!--honor-box start-->
        <div class="honor-box">
          <!--box-fl start-->
          <div class="box-fl">
            <div class="fl-line">
              <img src="../../assets/image/personage/h5.png" />
            </div>
            <div class="fl-item">
              <div class="c-block"><div class="block h10"></div></div>
              <div class="name">一星</div>
            </div>
            <div class="fl-item">
              <div class="c-block"><div class="block h26"></div></div>
              <div class="name">二星</div>
            </div>
            <div class="fl-item">
              <div class="c-block"><div class="block h42"></div></div>
              <div class="name">三星</div>
            </div>
            <div class="fl-item">
              <div class="c-block"><div class="block h68"></div></div>
              <div class="name">四星</div>
            </div>
            <div class="fl-item">
              <div class="c-block"><div class="block h96"></div></div>
              <div class="name">五星</div>
            </div>
            <div class="fl-item active">
              <div class="c-block"><div class="block h110"></div></div>
              <div class="name">经理</div>
              <div class="fl-icon">
                <img src="../../assets/image/personage/h2.png" />
              </div>
            </div>
          </div>
          <!--box-fl end-->
          <!--box-fr start-->
          <div class="box-fr">
            <div class="fr-con">
              <div class="fr-icon">
                <img src="../../assets/image/personage/h1.png" />
              </div>
              <div class="fr-li" v-for="(item,index) in ruleList" :key="index">{{ item }}</div>
            </div>
          </div>
          <!--box-fr end-->
        </div>
        <!--honor-box end-->
        <!--ind-bd start-->
        <div class="ind-bd">
          <div class="bd-line"></div>
          <!--bd-item start-->
          <div class="bd-item" v-for="(item, index) in list" :key="index">
            <div class="circle">
              <div class="ii"></div>
            </div>
            <div class="t">
              晋升星级：<span class="color-3">{{ item.content }}</span>
            </div>
            <div class="d">{{ item.createtime }}</div>
          </div>
          <!--bd-item end-->
        </div>
        <!--ind-bd end-->
        <div class="paging" v-if="list.length">
          <el-pagination
            background
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!--ind-main end-->

      <!-- 他人晋升 -->
      <!--comm-main start-->
      <div class="comm-main" v-else-if="promoteIndex == 1">
        <!--comm-bd start-->
        <div class="comm-bd">
          <!--agree-head start-->
          <div class="agree-head">
            <div class="key_page_select">
              <div class="select_item">
                <div class="label">时间:</div>
                <div class="select">
                  <el-date-picker
                    @change="changeData"
                    v-model="date_time"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="select_item">
                <div class="label">区域:</div>
                <div class="select">
                  <el-cascader
                    size="large"
                    :options="regionList"
                    v-model="areaList"
                    placeholder="请选择"
                    @change="handleChangeCity"
                  >
                  </el-cascader>
                </div>
              </div>
              <div class="select_item">
                <div class="label">门店:</div>
                <div class="select">
                  <el-select
                    @change="changeData"
                    v-model="dian_id"
                    placeholder="请选择"
                    size="160px"
                  >
                    <el-option
                      v-for="item in shopList"
                      :key="item.id"
                      :label="item.dian_name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="select_item">
                <div class="label">职位:</div>
                <div class="select">
                  <el-select
                    @change="changeData"
                    v-model="position"
                    placeholder="请选择"
                    size="160px"
                  >
                    <el-option
                      v-for="item in positionList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="select_item">
                <div class="label">姓名:</div>
                <div class="select">
                  <el-select filterable
                    remote
                    clearable
                    @change="changeData"
                    :remote-method="getxius" v-model="name" placeholder="请选择">
                    <el-option
                      v-for="item in userList"
                      :key="item.user_id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <!--agree-head end-->
          <!--affairs-content start-->
          <div class="affairs-content">
            <el-table
              :data="list"
              stripe
              style="width: 100%">
              <el-table-column
                prop="createtime"
                label="时间"
                width="140">
              </el-table-column>
              <el-table-column
                prop="city"
                label="区域"
                width="180">
              </el-table-column>
              <el-table-column
                width="120"
                prop="dian_name"
                label="门店">
              </el-table-column>
              <!-- <el-table-column
                prop="position"
                label="职位"
                width="100">
              </el-table-column> -->
              <el-table-column
                label="职位"
                width="100">
                <template slot-scope="scope">{{ getPosition(scope.row.position) }}</template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="姓名"
                width="80">
              </el-table-column>
              <el-table-column
                prop="job_number"
                label="工号">
              </el-table-column>
               <el-table-column
                prop="content"
                label="晋升星级">
              </el-table-column>
            </el-table>
            <div class="paging">
              <el-pagination
                background
                @current-change="changePage"
                layout="prev, pager, next, jumper, ->, total"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
          <!--affairs-content end-->
        </div>
        <!--comm-bd end-->
      </div>
      <!--comm-main end-->
    </template>
    <template v-else-if="headIndex == 1">
      <!--honor-head start-->
      <div class="honor-head">
        <div class="head-nav">
          <div class="li" @click="changePromoteIndex(index)" :class="{active:promoteIndex == index}" v-for="(item,index) in 2" :key="index">{{ index == 0 ? '我的获奖' : '他人获奖' }}</div>
        </div>
      </div>
      <!--honor-head end-->
      <!--ind-main start-->
      <div class="ind-main" v-if="promoteIndex == 0">
        <div class="award-hd-img">
          <img src="../../assets/image/personage/h3.png" />
        </div>
        <!--ind-bd start-->
        <div class="ind-bd">
          <div class="bd-line"></div>
          <!--bd-item start-->
          <div class="bd-item" v-for="(item, index) in list" :key="index">
            <div class="circle">
              <div class="ii"></div>
            </div>
            <div class="t">
              获得奖项：<span class="color-3">{{ item.content }}</span>
            </div>
            <div class="d">{{ item.createtime }}</div>
          </div>
          <!--bd-item end-->
        </div>
        <div class="paging">
          <el-pagination
            background
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
        <!--ind-bd end-->
      </div>
      <!--comm-main start-->
      <div class="comm-main" v-else-if="promoteIndex == 1">
        <!--comm-bd start-->
        <div class="comm-bd">
          <!--agree-head start-->
          <div class="agree-head">
            <div class="key_page_select">
              <div class="select_item">
                <div class="label">时间:</div>
                <div class="select">
                  <el-date-picker
                    @change="changeData"
                    v-model="date_time"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="select_item">
                <div class="label">区域:</div>
                <div class="select">
                  <el-cascader
                    size="large"
                    :options="regionList"
                    v-model="areaList"
                    placeholder="请选择"
                    @change="handleChangeCity"
                  >
                  </el-cascader>
                </div>
              </div>
              <div class="select_item">
                <div class="label">门店:</div>
                <div class="select">
                  <el-select
                    @change="changeData"
                    v-model="dian_id"
                    placeholder="请选择"
                    size="160px"
                  >
                    <el-option
                      v-for="item in shopList"
                      :key="item.id"
                      :label="item.dian_name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="select_item">
                <div class="label">职位:</div>
                <div class="select">
                  <el-select
                    @input="changeData"
                    v-model="position"
                    placeholder="请选择"
                    size="160px"
                  >
                    <el-option
                      v-for="item in positionList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="select_item">
                <div class="label">姓名:</div>
                <div class="select">
                  <!-- <el-input
                    @input="changeData"
                    v-model="name"
                    placeholder="请输入"
                  ></el-input> -->
                  <el-select filterable
                    remote
                    clearable
                    @change="changeData"
                    :remote-method="getxius" v-model="name" placeholder="请选择">
                    <el-option
                      v-for="item in userList"
                      :key="item.user_id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <!--agree-head end-->
          <!--affairs-content start-->
          <div class="affairs-content">
            <table class="affairs-table">
              <tr>
                <th>时间</th>
                <th>区域</th>
                <th>门店</th>
                <th>职位</th>
                <th>姓名</th>
                <th>工号</th>
                <th>获得奖项</th>
              </tr>
              <tr v-for="(item, index) in list" :key="index">
                <td>{{ item.createtime }}</td>
                <td>{{ item.city }}</td>
                <td>{{ item.dian_name }}</td>
                <td>{{ getPosition(item.position) }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.job_number }}</td>
                <td>{{ item.content }}</td>
              </tr>
            </table>
            <div class="paging" v-if="list.length">
              <el-pagination
                background
                layout="prev, pager, next, jumper, ->, total"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
          <!--affairs-content end-->
        </div>
        <!--comm-bd end-->
      </div>
      <!--comm-main end-->
    </template>
    <!-- 申请晋升 S -->
    <apply-promote ref="applyPromote" @success="init"></apply-promote>
    <!-- 申请晋升 E -->
    <!--奖励弹框 S -->
    <award-pop ref="pop"></award-pop>
    <!-- 经理弹框 E -->
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import IndividualPop from "../../components/IndividualPop";
import { regionData, CodeToText } from "element-china-area-data";
import ApplyPromote from "@/components/personage/ApplyPromote.vue";
import AwardPop from "@/components/AwardPop";
// import 'swiper/css/swiper.css'
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { IndividualPop, ApplyPromote, AwardPop },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"])
  },
  data() {
    return {
      headIndex: 0, //头部切换
      areaList: [], //
      regionList: regionData,
      CodeToText: CodeToText,
      promoteIndex: 0, //
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      date_time: "", //时间
      dian_id: "", //门店id
      position: "", //职位；传：1=区域总监2=店长3=经理4=经纪人
      name: "", //名称
      options: [],
      value:'',
      type: 2, //1 违规；2 晋升；3 获奖
      shopList: [], //门店列表
      ruleList:[],//规则列表
      userList: [], //
    };
  },
  methods: {
    changeHeadIndex(index) {
      this.headIndex = index;
      this.init();
    },
    changeData() {
      this.init();
    },
    init() {
      this.page = 1;
      this.getweiGuiLogList();
    },
    //申请晋升
    applyPromote() {
      this.$refs.applyPromote.open();
    },
    //切换
    changePromoteIndex(index) {
      this.promoteIndex = index;
      this.init();
    },
    //选择位置
    handleChangeCity() {
      let data = [];
      for (let i = 0; i < this.areaList.length; i++) {
        data.push(this.CodeToText[this.areaList[i]]);
      }
      // this.ruleForm.city = data.join("/");
    },
    changePage (e) {
      this.page = e
      this.getweiGuiLogList()
    },
    getweiGuiLogList() {
      this.$axios
        .weiGuiLogList({
          type: this.headIndex == 0 ? 2 : 3,
          page: this.page,
          date_time: this.promoteIndex == 0 ? "" : this.date_time,
          role_type: this.promoteIndex + 1,
          name: this.promoteIndex == 0 ? "" : this.name,
          position: this.promoteIndex == 0 ? "" : this.position,
        })
        .then((res) => {
          this.list = res.data.data;
          this.total = res.data.total;
        });
    },
    getmenDian() {
      this.$axios.menDian().then((res) => {
        this.shopList = res.data;
      });
    },
    getJinShengRule () {
      this.$axios.JinShengRule().then(res=>{
        let data= []
        res.data.split(';').forEach(element => {
          if (element) {
            data.push(element)
          }
        });
        this.ruleList = data
      })
    },
    getxius(e) {
      this.$axios
        .userList({
          name:e,
          position: 4,
        })
        .then((res) => {
          this.userList = res.data;
        });
    },
  },
  created() {
    this.getxius('');
    this.getmenDian();
    this.getJinShengRule()
    this.getweiGuiLogList();
  },
}
</script>

<style scoped lang="less">
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #ebebeb;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
  .award {
    width: 88px;
    height: 36px;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #FFFFFF;
    font-size: 16px;
    justify-content: center;
    background: #FFA740;
    border-radius: 4px;
  }
}
.honor-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  background: #fff;
  margin-bottom: 10px;
  align-items: center;
  .head-nav {
    display: flex;
  }
  .li {
    cursor: pointer;
    margin-right: 55px;
    line-height: 36px;
  }
  .nav-link {
    font-size: 16px;
    color: #666;
    line-height: 36px;
  }
  .active {
    color: #3273f6;
  }
}
.honor-box {
  display: flex;
  justify-content: space-between;
  .box-fl {
    width: 333px;
    position: relative;
    display: flex;
    .fl-line {
      position: absolute;
      left: 0px;
      top: 0px;
    }
    .fl-item {
      width: 40px;
      position: relative;
      margin-right: 18px;
    }
    .name {
      font-size: 16px;
      text-align: center;
      margin: 10px 0px;
    }
    .c-block {
      height: 190px;
      position: relative;
    }
    .block {
      background: #6d9dff;
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 40px;
    }
    .h10 {
      height: 10px;
    }
    .h26 {
      height: 26px;
    }
    .h42 {
      height: 42px;
    }
    .h68 {
      height: 68px;
    }
    .h96 {
      height: 96px;
    }
    .h110 {
      height: 110px;
    }
    .active .name {
      color: #3273f6;
    }
    .active .block {
      background: #3273f6;
    }
    .fl-icon {
      position: absolute;
      left: 0px;
      top: 30px;
    }
    .fl-icon {
      width: 40px;
    }
  }
  .box-fr {
    width: 460px;
    .fr-con {
      background: #fffdf8;
      padding: 15px 20px;
      position: relative;
      border: 1px solid #ffa740;
      border-radius: 8px;
      min-height: 100px;
      .fr-icon {
        position: absolute;
        right: 0px;
        bottom: 0px;
      }
      .fr-icon img {
        width: 72px;
      }
      .fr-li {
        padding-left: 20px;
        position: relative;
        font-size: 14px;
        line-height: 24px;
        margin: 10px 0px;
      }
      .fr-li:before {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 8px;
        content: "";
        background: #ffa740;
      }
    }
  }
}
.ind-add-btn {
  width: 98px;
  height: 36px;
  border-radius: 4px;
  background: #3273f6;
  padding: 0px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  color: #fff;
}
.ind-main {
  background: #fff;
  padding: 40px;
  .ind-bd {
    position: relative;
    margin-top: 40px;
    .bd-line {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 2px;
      background: #7ba6fe;
      height: 100%;
    }
    .bd-item {
      background: #f4f8ff;
      border-radius: 4px;
      padding: 10px 30px;
      position: relative;
      margin: 20px 0px 20px 30px;
      display: flex;
      justify-content: space-between;
      .circle {
        width: 22px;
        height: 22px;
        border-radius: 100%;
        position: absolute;
        left: -40px;
        top: 50%;
        margin-top: -6px;
        border: 1px solid #7ba6fe;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        .ii {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #7ba6fe;
        }
      }
      .d {
        width: 150px;
        text-align: right;
        font-size: 14px;
        line-height: 24px;
      }
      .t {
        width: calc(100% - 150px);
        font-size: 16px;
        color: #666;
        line-height: 24px;
      }
    }
  }
}
.comm-main {
  background: #fff;
  padding: 25px 30px;
  .comm-hd {
    width: 690px;
    height: 210px;
    background: url("../../assets/image/personage/money.png") no-repeat center;
    background-size: cover;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    .c {
      font-size: 42px;
      margin-top: 15px;
    }
    .t {
      font-size: 20px;
    }
  }
  .comm-tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      font-size: 18px;
      padding-left: 10px;
      position: relative;
    }
    .name:before {
      display: block;
      width: 4px;
      height: 20px;
      position: absolute;
      left: 0px;
      top: 50%;
      margin-top: -10px;
      content: "";
      background: #3273f6;
      border-radius: 2px;
    }
    .comm-select {
      /deep/ .el-input {
        width: 160px;
      }
      /deep/ .el-input__inner {
        border: 1px solid #ccc;
        border-radius: 0;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
      }
    }
  }
  .comm-list {
    .comm-item {
      display: flex;
      border-bottom: 1px solid #ebebeb;
      padding: 15px 0px;
      justify-content: space-between;
      align-items: center;
      .item-l {
        display: flex;
        align-items: center;
        width: calc(100% - 150px);
        .icon {
          width: 44px;
          height: 44px;
        }
        .txt {
          margin-left: 20px;
          .t {
            font-size: 16px;
            line-height: 1.6;
          }
          .b {
            display: flex;
            font-size: 14px;
            line-height: 1.6;
            margin: 5px -20px;
          }
          .b-s {
            line-height: 1.6;
            margin: 0 20px;
          }
        }
      }
      .d {
        color: #999;
        font-size: 14px;
        margin-top: 5px;
        line-height: 1.6;
      }
      .item-r {
        width: 150px;
        text-align: right;
        .add-num {
          font-size: 24px;
          color: #ff2121;
        }
      }
    }
  }
}
.key_page_select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  .select_item {
    display: flex;
    font-size: 14px;
    color: #666;
    align-items: center;
    padding: 10px 0px;
    margin: 0 10px;
  }
  .label {
    margin-right: 10px;
    white-space: nowrap;
    width: 80px;
    text-align: right;
  }
  .select {
    width: 200px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
  /deep/ .el-date-editor.el-input {
    width: 200px;
  }
}
.affairs-content {
  margin: 20px 10px;
}
.affairs-table {
  width: 100%;
  border: 1px solid #ebebeb;
  border-collapse: collapse;
}
.affairs-table th {
  background: #f5f5f5;
  border: 1px solid #ebebeb;
  border-top: none;
  border-bottom: none;
  padding: 15px 10px;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
}
.affairs-table td {
  padding: 15px 10px;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  text-align: center;
  font-size: 14px;
}
.affairs-table-btn {
  display: flex;
  .btn {
    padding: 0px;
    background: none;
    outline: none;
    margin: 5px;
    border: none;
  }
  .color-orange {
    color: #ff9600;
  }
  .color-red {
    color: #ff5151;
  }
}
.affairs-table tr td {
  background: #f7fcff;
}
.affairs-table tr:nth-child(2n) td {
  background: #fff;
}
</style>
