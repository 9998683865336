// 借钥匙
<template>
  <div class="examRule">
    <el-dialog
        title=""
        :show-close="false"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="568px"
    >
      <div class="examRule_head flex ">
        <span>奖励</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="examRule_main p-t-30 p-r-40 p-b-30 p-l-40">
        <div class="borrow-form">
          <el-form
              ref="form"
              :rules="rules"
              class="form"
              :model="form"
              label-width="100px"
          >
            <el-form-item label="店铺:" prop="dian_id">
              <el-select v-model="form.dian_id" placeholder="请选择">
                <el-option
                    v-for="item in shopList"
                    :key="item.id"
                    :label="item.dian_name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="姓名:" prop="name">
              <!-- <el-input v-model="form.number" placeholder=""></el-input> -->
              <el-select @change="changeName" v-model="form.name" placeholder="请选择姓名">
                <el-option
                    v-for="item in userList"
                    :key="item.user_id"
                    :label="item.name"
                    :value="item.user_id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工号:" prop="user_id">
              <el-input v-model="form.user_id" placeholder="" disabled></el-input>
            </el-form-item>
            <!-- <el-form-item label="星级:" prop="phone">
              <el-input v-model="form.phone" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="电话:" prop="phone">
              <el-input v-model="form.phone" placeholder=""></el-input>
            </el-form-item> -->
            <el-form-item label="获得奖项:" prop="content">
              <el-input v-model="form.content" placeholder="请输入奖项内容"></el-input>
            </el-form-item>
          </el-form>
          <div class="interview_main_footer">
            <el-button @click="submit('form')">提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible:false,
      form: {
        type:3,
        user_id:'',
        dian_id:'',
        content:'',//内容
      },
      rules:{
        content: [
            { required: true, message: '请输入奖项内容', trigger: 'blur' }
          ],
          user_id: [
            { required: true, message: '请选择姓名', trigger: 'change' }
          ],
          dian_id: [
            { required: true, message: '请选择门店', trigger: 'change' }
          ],
          name: [
            { required: true, message: '请选择姓名', trigger: 'change' }
          ],
      },
      userList:[],//用户列表
      shopList:[],//门店id
    }
  },
  methods:{
    changeName (e) {
      this.form.user_id = e
    },
    submit (formName) {
      this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.addPromotion2(this.form).then(res=>{
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'success'
              });
              this.dialogVisible = false
            })
          } else {
            return false;
          }
        });
    },
    //关闭
    close () {
      this.dialogVisible = false
    },
    //打开
    open () {
      this.dialogVisible = true
      this.$axios.userList().then(res=>{
        this.userList = res.data
      })
      this.$axios.menDian().then(res=>{
        this.shopList = res.data
      })
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header ,/deep/ .el-dialog__body {
  padding: 0 !important;
  /deep/ .el-input__inner{border:1px solid #ccc;border-radius:0;font-size:16px;}
  
}
/deep/ .el-select,/deep/ .el-select{width:100%;}
/deep/ .el-dialog{border-radius:8px;overflow:hidden}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;display:block;margin:0 auto;
  }
  .bor-btn{
    width: 480px;
    height: 56px;
    font-size: 18px;display:block;margin:20px auto 0 auto;
    border:1px solid #3273F6;color:#3273F6;
    border-radius: 28px;line-height:56px;text-align:center;
  }
}
</style>
