// 申请晋升
<template>
  <div class="applyPromote">
    <el-dialog title="" :show-close="false" :close-on-click-modal="false" :visible.sync="dialogVisible" width="600px">
      <div class="applyPromote_box">
        <div class="examRule_head flex">
          <span>申请晋升</span>
          <i class="el-icon-circle-close" @click="close"></i>
        </div>
        <div class="applyPromote_main">
          <div class="applyPromote_main_top flexs">
            <span class="m-r-20">晋升职位</span>
            <el-select v-model="from.position" placeholder="请选择">
              <el-option v-for="item in list" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="applyPromote_con">
            <div class="applyPromote_ipt">
              <el-input type="textarea" resize="none" placeholder="请输入内容" v-model="from.content">
              </el-input>
            </div>
          </div>
          <div class="applyPromote_footer">
            <el-button type="primary" @click="submit" round>提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      textarea: "",
      from: {
        position: "",
        content: "",
        type: 3,
      },
      list: [
        {
          value: "1",
          label: "区域总监",
        },
        {
          value: "2",
          label: "店长",
        },
        {
          value: "3",
          label: "经理",
        },
        {
          value: "5",
          label: "一星经纪人",
        },
        {
          value: "6",
          label: "二星经纪人",
        },
        {
          value: "7",
          label: "三星经纪人",
        },
        {
          value: "8",
          label: "四星经纪人",
        },
        {
          value: "9",
          label: "五星经纪人",
        }
      ]
    };
  },
  methods: {
    //提交
    submit() {
      if (!this.from.position) return this.$message.error("请选择职位");
      if (!this.from.content) return this.$message.error("请输入内容");
      this.$axios.addPromotion(this.from).then((res) => {
        this.from.position = ''
        this.from.content = ''
        this.dialogVisible = false;
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.$emit('success')
      });
    },
    close() {
      this.dialogVisible = false;
    },
    open() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0;
}

.applyPromote_main {
  background: #ffffff;
  padding: 30px 20px 50px 20px;

  .applyPromote_ipt {
    height: 240px;
    padding: 20px;
    margin-top: 30px;
    background: #f5f5f5;
    border-radius: 8px;

    /deep/ .el-textarea {
      height: 100%;
    }

    /deep/ .el-textarea__inner {
      height: 100%;
      border: none;
      padding: 0;
      background: none;
    }
  }

  .applyPromote_footer {
    margin-top: 60px;
    width: 100%;
    padding: 0 40px;

    /deep/ .el-button {
      width: 100%;
      background: #3273f6;
    }
  }
}
</style>
>
